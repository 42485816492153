<template>
  <main class="sub __contact" ref="sub-container">
    <router-view></router-view>
  </main>
</template>

<script>
export default {
  components: {},
}
</script>

<style></style>
